import React, { useCallback, useEffect } from "react";
import MainContainer from "./components/home/MainContainer";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useStateValue } from "./components/context/StateProvider";
import {
  getAllProductsItems,
  getAllOrders,
} from "./components/utils/firebaseFunctions";
import Home from "./components/home/pikestore/Home";
import Header from "./components/home/pikestore/Header";
import Detalle from "./components/home/pikestore/Detalle";
import Headerup2 from "./components/home/pikestore/Headerup2";
import Footer from "./components/home/pikestore/Footer";
import Contact from "./components/home/pikestore/Contact";
import About from "./components/home/pikestore/About";
import Upload from "./components/home/pikestore/Upload";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { cel } from "./components/Data";
function App() {
  const [
    {
      headerShow,
      dondeestoy,
      cartShow,
      editShow,
      favorite,
      loginShow,
      user,
      products,
    },
    dispatch,
  ] = useStateValue();
  const [load, upadateLoad] = React.useState(true);

  const fetchData = useCallback(() => {
    /* fetch("https://api.mercadolibre.com/sites/MLU/search?seller_id=109907868")
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: actionType.SET_PRODUCTS,
          products: data.results,
        });
        dispatch({
          type: actionType.SET_CATEGORIES,
          categories: data.available_filters[0].values,
        });
      }); */
    fetch("http://localhost:1337/api/productos")
      .then((response) => response.json())
      .then((data) => {
        /*  dispatch({
          type: actionType.SET_PRODUCTS,
          products: data.results,
        });
        dispatch({
          type: actionType.SET_CATEGORIES,
          categories: data.available_filters[0].values,
        }); */
        console.log(data);
      });
  }, []);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      upadateLoad(false);
    }, 1000);

    fetchData();
    /* fetchApi(); */
  }, []);

  const options = {
    DesarrolladorCodigo: "SGONZALEZ",
    DesarrolladorClave: "7CD8091216BFBE9E66815D44032AB0F1",
    EmpresaCodigo: "PIKESTORE",
    EmpresaClave: "FE0721B69CCC5299F7119E0951F8E98B",
    RolCodigo: 3,
  };

  return (
    <div className="w-screen h-screen bg-white ">
      <main className=" ">
        <div className="z-[1000] fixed">
          <WhatsAppWidget
            className="z-[10000]"
            phoneNumber={cel}
            sendButton="Enviar"
            message="Hola! 👋🏼 En que te podemos ayudar?"
            replyTimeText="Suele responder dentro de 2 horas"
            companyName="Ingenal"
          />
        </div>
        <Routes location={location} key={location.pathname}>
          <Route path="/*" element={<Home />} />
          <Route path="/detalle/:productId" element={<Detalle />} />
          <Route path="/SobreNosotros" element={<About />} />
          <Route path="/Contacto" element={<Contact />} />
          <Route path="/Admin" element={<Upload />} />
        </Routes>
        <Footer />
      </main>
    </div>
  );
}

export default App;
