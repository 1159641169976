import React from "react";

const Capacitaciones = ({ item }) => {
  console.log(item);
  return (
    <div className="scale-90 hover:scale-100 transition ease-in duration-300">
      <img
        src={item.img}
        className="w-full rounded-t-lg h-[300px] object-cover "
        alt="img"
      />
      <div className="h-[150px] p-4 rounded-b-lg flex-col flex shadow-md hover:shadow-xl transition 2s ease-in">
        <h1 className="text-[0.8rem] font-bold flex justify-center w-[200px] mx-auto text-center text-inge1">{item.name}</h1>

        <a href={item.link} className="flex text-center cursor-pointer justify-center font-bold mt-6  p-2 w-full mx-auto rounded-lg bg-inge1 text-inge2">Más información</a>
      </div>
    </div>
  );
};

export default Capacitaciones;
