import React from "react";
import logo from "./assets/logo.jpg";
import {
  AiOutlineInfoCircle,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineMail,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";

const Footer = () => {
  return (
    <>
      {/*  Desktop */}
      <footer className="w-full md:flex justify-between items-center px-40 bg-inge1 p-2 py-10 hidden">
        {/*  <div className="flex gap-4">
         <div className="flex items-center">
          <AiOutlineInfoCircle className="text-pike" />
          <p className="text-white ml-2">
            Retiros en Zona Reducto - Parque Posadas
          </p>
        </div> 
       
      </div> */}
        <ul className="text-white flex flex-col gap-2">
          <h1 className="font-bold ">Contacto</h1>
          <div className="flex gap-2 items-center">
            <a href="https://wa.me/+59899276400">
              {" "}
              <AiOutlineWhatsApp className="text-[2rem] cursor-pointer" />
            </a>

            <li className="hover:underline cursor-pointer">099 276 400</li>
          </div>
          <div className="flex gap-2 items-center">
            <a href="mailto:ingenal@gmail.com">
              {" "}
              <AiOutlineMail className="text-[2rem]  cursor-pointer" />
            </a>
            <li className="hover:underline cursor-pointer">
              ingenal@gmail.com
            </li>
          </div>
          <li></li>
        </ul>
        <ul className="text-white flex flex-col gap-4">
          <h1 className="font-bold ">Donde encontrarnos</h1>
          <div>
            <li className="font-bold">Nueva Palmira 1894</li>
            <li>Montevideo, Uruguay.</li>
          </div>

          <li></li>
        </ul>
        <ul className="text-white flex flex-col gap-2">
          <h1 className="font-bold ">Nosotros</h1>
          <li className="hover:underline cursor-pointer">Sobre nosotros</li>
          <li className="hover:underline cursor-pointer">Servicios</li>
          <li className="hover:underline cursor-pointer">Capacitaciones</li>
        </ul>
      </footer>

      {/* Mobile */}
      <footer className="w-full md:hidden   px-8 bg-inge1 p-2 py-10 flex flex-col">
       {/*  <div className="flex gap-4">
         <div className="flex items-center">
          <AiOutlineInfoCircle className="text-pike" />
          <p className="text-white ml-2">
            Retiros en Zona Reducto - Parque Posadas
          </p>
        </div> 
       
      </div> */}
        <ul className="text-white flex flex-col gap-2">
          <h1 className="font-bold ">Contacto</h1>
          <div className="flex gap-2 items-center">
            <a href="https://wa.me/+59899276400">
              {" "}
              <AiOutlineWhatsApp className="text-[2rem] cursor-pointer" />
            </a>

            <li className="hover:underline cursor-pointer">099 276 400</li>
          </div>
          <div className="flex gap-2 items-center">
            <a href="mailto:ingenal@gmail.com">
              {" "}
              <AiOutlineMail className="text-[2rem]  cursor-pointer" />
            </a>
            <li className="hover:underline cursor-pointer">
              ingenal@gmail.com
            </li>
          </div>
          <li></li>
        </ul>
        <ul className="text-white flex flex-col gap-4 mt-4">
          <h1 className="font-bold ">Donde encontrarnos</h1>
          <div>
            <li className="font-bold">Nueva Palmira 1894</li>
            <li>Montevideo, Uruguay.</li>
          </div>

          <li></li>
        </ul>
        <ul className="text-white flex flex-col gap-2">
          <h1 className="font-bold ">Nosotros</h1>
          <li className="hover:underline cursor-pointer">Sobre nosotros</li>
          <li className="hover:underline cursor-pointer">Servicios</li>
          <li className="hover:underline cursor-pointer">Capacitaciones</li>
        </ul>
         
      </footer>
    </>
  );
};

export default Footer;
