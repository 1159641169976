import React from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import fichaSTH1265A from "./assets/MANIPULADOR_STH1265A.pdf";
import { AiOutlineDownload,AiOutlineInfo, AiFillInfoCircle } from "react-icons/ai";
import ReactPlayer from "react-player";



const Products = ({ item, setModal, setItem}) => {
  const setear = (item) => {
setModal(true)
setItem(item)
  }
  return (
  
    <div className="cursor-pointer scale-95 hover:scale-100 transition ease-in duration-300">
     
      <img src={item.img[0]} onClick={() => setear(item)} className="w-full rounded-t-lg h-[200px] object-cover" />
      <div className="relative  justify-center items-center p-4 rounded-b-lg flex-col flex shadow-md hover:shadow-xl transition 2s ease-in">
        <h1 className="text-[1rem]  text-center font-bold flex justify-center text-inge1">
          {item.name}
        </h1>
        <h1 className="text-[1rem] font-semibold flex justify-center">
          {item.tone} Toneladas
        </h1>
        {
          item.chofer === false && 
           <h1 className="text-[1rem] flex justify-center">
       Sin chofer
        </h1>
        }
       


        <p className="flex text-[1.5rem] text-center justify-center font-bold  p-2 w-full mx-auto rounded-lg text-black">
          {item.price}{" "}
        {
item.mes && item.mes

        }
          <span className="text-[1rem] flex items-center ml-1">USD</span>{" "} 
          {
item.mes &&  <span className="text-[1rem] flex items-center ml-1">/ mes</span>

        }
        </p>
    
     {
          item.chofer === false ?
          <a
          className="flex items-center gap-2 justify-center text-white rounded-lg w-[100px]  text-[1rem]  bg-orange-400 "
        >
     Alquiler
        </a> :  <a
          className="flex  items-center gap-2 w-[100px] justify-center text-white rounded-lg   text-[1rem]  bg-red-400 "
        >
     Venta
        </a>
        }
            {
          item.ficha &&
          <a
          className="flex  mt-2 items-center gap-2 justify-center text-gray-600 rounded-lg   text-[1rem] p-2 bg-yellow-300 "
          href={fichaSTH1265A}
        >
          {" "}
       Ficha tecnica <AiFillInfoCircle/>
        </a>
        }
      </div>
    </div>
  );
};

export default Products;
