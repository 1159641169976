import React from "react";
import logo from "./assets/logo-web.svg";
import { AiOutlineShoppingCart, AiFillMail,AiOutlineClose,AiOutlineMenu } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useStateValue } from "../../context/StateProvider";
import { actionType } from "../../context/reducer";
import { cel, mail } from "../../Data";

const Header = ({handleClick, handleClickCatalogo,  handleClickContacto }) => {
  const [{ cartItems, cartShow }, dispatch] = useStateValue();
  const [menu, setMenu] = React.useState("Inicio");
  const [menuopen, setMenuopen] = React.useState(false);
  const navigate = useNavigate();
  const showCart = () => {
    dispatch({
      type: actionType.SET_CART_SHOW,
      cartShow: !cartShow,
    });
  };

  const [scroll, setY] = React.useState(window.scrollY);

  const handleNavigation = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollY);
    },
    [scroll]
  );

  const menunav = [
    {
      id: 0,
      name: "Inicio",
    },
    {
      id: 1,
      name: "Catálogo",
    },
    {
      id: 1,
      name: "Capacitaciones",
    },
   /*  {
      id: 2,
      name: "Sobre Nosotros",
    }, */
    {
      id: 3,
      name: "Contacto",
    },
  ];

  const Burgermenu = () => {
    return (
      <div className="h-[100vh] bg-white w-[100vw] fixed top-0 left-0 z-[2000] ">
      <AiOutlineClose  onClick={() => setMenuopen(false)}  className="absolute right-10 top-10 font-bold text-[2rem]"/>
    {
       <ul className="flex flex-col gap-4 text-right text-pike2 cursor-pointer w-full h-full  items-center justify-center ">
       {menunav.map((a) => (
         <li
        onClick={() => navegar(a.name)} 
           className={`${menu === a.name ? "text-inge1 font-bold" : "text-pike2 font-medium" } `}
         >
           {a.name}
         </li>
       ))}
     </ul>
     }
    </div>
    )
  }
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const scrollTo = () => {
  };

  const navegar = (a) => {
    setMenu(a);
setMenuopen(false)
    if (a === "Inicio") {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } 
    if (a === 'Catálogo') {
      handleClickCatalogo()
        } 
    if (a === 'Capacitaciones') {
      handleClick()
    } 
    if (a === 'Contacto') {
      handleClickContacto() 
       } 
  };
  return (
    <>
    {/* DESKTOP */}
    <nav
      className='fixed top-0 md:text-[0.8rem] lg:text-[1rem] w-full hidden md:flex z-[100] bg-white justify-between items-center md:px-20 lg:px-40 h-16 transition-all ease-in 2s'
    >
      {/*       <img src={logo} alt="logo" className="flex w-20" />
       */}{" "}
       <img src={logo} alt="logo" className="flex md:w-10 lg:w-14" />
      {/* <p
        className="font-bold text-[1.5rem] cursor-pointer"
        onClick={() => navigate("/")}
      >
        PIKE STORE
      </p> */}
      <div className="flex gap-8">
          {menunav.map((a) => (
            <div
              onClick={() => navegar(a.name)}
              className={`${menu === a.name ? "text-inge1 font-bold cursor-pointer" : "text-gray-500 font-medium cursor-pointer" } `}
            >
              {a.name}
            </div>
          ))}
  
       <a href={`tel:${cel}`}>

       <BsTelephoneFill className="flex z-100 cursor-pointer items-center text-[1.2rem] text-inge1" />

       </a>
       <a href={`mailto:${mail}`}>

          <AiFillMail className="flex z-100 cursor-pointer text-[1.4rem] text-inge1" />
        </a>
          </div>
    </nav>



    {/* MOBILE */}
    <nav
      className={`fixed w-full flex md:hidden z-[100] bg-white justify-between items-center px-4 h-16 transition-all ease-in 2s`}
    >
      {/*       <img src={logo} alt="logo" className="flex w-20" />
       */}{" "}
              <img         onClick={() => navigate("/")}
 src={logo} alt="logo" className="flex w-10 md:w-10 lg:w-14" />

     
     {
      menuopen && <Burgermenu/>
     }
      <div className="flex gap-2">
      
       
        <AiOutlineMenu onClick={() => setMenuopen(true)} className="flex z-100 cursor-pointer text-[1.8rem] text-gray-500"/>

      </div>
    </nav>
    </>
  );
};

export default Header;
