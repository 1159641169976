import React, { useRef, useState } from "react";
import Products from "./Products";
import Slider from "./Slider";
import Range from "./Range";
import Pagination from "./Pagination";
import {
  IoIosConstruct,
  IoMdArrowDropleft,
  IoMdArrowDropright,
} from "react-icons/io";
import {
  AiFillHome,
  AiOutlineSearch,
  AiOutlineFilter,
  AiFillCreditCard,
  AiOutlineDropbox,
} from "react-icons/ai";
import { GiFactory, GiClothes, GiCoinsPile } from "react-icons/gi";
import { BsFillMusicPlayerFill, BsTruck } from "react-icons/bs";
import { FaBaby, FaTruck } from "react-icons/fa";
import { useStateValue } from "../../context/StateProvider";
import { actionType } from "../../context/reducer";
import { useLocation } from "react-router";
import banner from "./assets/banner2.jpg";
import Capacitaciones from "./Capacitaciones";
import Contact from "./Contact";
import ute from "../pikestore/assets/utelogo.png";
import imm from "../pikestore/assets/immlogo.png";
import ancap from "../pikestore/assets/Logo_ancap.png";
import Header from "./Header";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";

const Home = () => {
  const [{ products }, dispatch] = useStateValue();
  const [categories, setCategories] = React.useState();
  const [categories2, setCategories2] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [item, setItem] = React.useState([]);
  const [select, setSelect] = React.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts =
    products && products.slice(firstPostIndex, lastPostIndex);
  const location = useLocation();
  const state = location.state;
  const [showModal, setShowModal] = React.useState(false);
  const [first, setfirst] = useState(0);

  const banners = [
    {
      id: 0,
      name: "Pagá en cuotas!",
    },
    {
      id: 1,
      name: "Envíos a todo el país",
    },
    {
      id: 2,
      name: "Retira en el día",
    },
  ];

  const ingenal = [
    {
      id: 0,
      name: "MANIPULADOR STH1265A",
      chofer: false,
      img: [
        "https://imgur.com/MCzJfl7.jpeg",
        "https://imgur.com/xn6fHJL.jpeg",
        "https://i.imgur.com/Xg7duiJ.jpeg",
        "https://imgur.com/SZNlhDn.jpeg",
      ],
      video: "https://i.imgur.com/XsKpn1M.mp4",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      mes: 4500,
      ficha: "MANIPULADOR_STH1265A.pdf",
    },
    {
      id: 1,
      name: "Grua #1",
      img: ["https://i.imgur.com/qAGVEyc.jpg"],
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 90000,
    },
    {
      id: 2,
      name: "Grua #2",
      img: ["https://imgur.com/V8GByVb.jpg"],
      tone: 36,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 36000,
      pluma: 50,
    },
    {
      id: 3,
      name: "Grua #3",
      img: ["https://imgur.com/xFMcxCx.jpg"],
      tone: 40,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 34000,
      pluma: 50,
    },
    {
      id: 4,
      name: "Grua #4",
      img: ["https://imgur.com/iZmumh9.jpg"],
      tone: 36,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 35000,
      pluma: 50,
    },
    {
      id: 5,
      name: "Grua #5",
      img: ["https://imgur.com/fbB0wJN.jpg"],
      tone: 40,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 37000,
      pluma: 50,
    },
    {
      id: 6,
      name: "Grua #6",
      img: ["https://imgur.com/lJwX4gv.jpg", "https://imgur.com/BoNaxtO.jpg"],
      tone: 45,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 45000,
      pluma: 50,
    },
    /*  {
      id: 1,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    }, */
    /* {
      id: 2,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 3,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 4,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 5,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 6,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },

    {
      id: 9,
      name: "Grua Modelo #AAA123",
      img: "https://gruaselcampello.com/wp-content/uploads/2022/03/Que-es-una-grua-telescopica-y-como-funciona-principal.jpg",
      tone: 90,
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    }, */
  ];

  const capref = useRef(null);
  const catref = useRef(null);
  const ctoref = useRef(null);

  const handleClick = () => {
    capref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickCatalogo = () => {
    catref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickContacto = () => {
    ctoref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const capacitacion = [
    {
      id: 0,
      name: "Curso de Seguridad e Higiene Industrial y Portuaria",
      img: "https://www.tecnacional.edu.ni/media/Higiene_y_seguridad.jpg.3000x2000_q85_crop-center.jpg",
      link: "https://www.ingenal.com/es/capacitaciones",
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 1,
      name: "Curso de Operador de Grúas Móviles",
      img: "https://irp.cdn-website.com/59b4a24a/DESKTOP/jpg/10106.jpg",
      link: "https://www.ingenal.com/es/capacitaciones",

      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
    {
      id: 2,
      name: "Curso de Operador de Autoelevador",
      img: "https://www.rocaportal.net/wp-content/uploads/2014/10/gruas-san-blas-autoelevador-nafta-y-gas-autoelevador-mitsubishi-nafta-gas-modelo-fg-15nm-713183-FGR.jpg",
      link: "https://www.ingenal.com/es/capacitaciones",
      Descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend mattis est, consectetur euismod",
      price: 25000,
    },
  ];
  React.useEffect(() => {
    /*  fetch("https://api.mercadolibre.com/sites/MLU/search?seller_id=109907868")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.available_filters[0].values);
        setPrice(data.available_filters[1].values);

        dispatch({
          type: actionType.SET_PRODUCTS,
          products: data.results,
        });
      }); */

    /* fetch("https://pikestoresv.herokuapp.com/api/productos?populate=*")
      .then((response) => response.json())
      .then((data) => {
      
        dispatch({
          type: actionType.SET_PRODUCTS,
          products: data.data,
        });
        console.log(data.data);
      });

    if (state === "catalogo") {
      const offsetPosition = 630;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else if (state === "inicio") {
      const offsetPosition = 0;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } */

    setSelect("todos");

    state !== "catalogo" &&
      setTimeout(() => {
        setShowModal(true);
      }, 3000);
  }, [state]);

  const Popup = () => {
    return (
      <>
        {showModal ? (
          <>
            <div className="flex bg-black bg-opacity-30 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[99999] outline-none focus:outline-none ">
              <div className="relative w-auto my-6 mx-auto">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[30vw] bg-white outline-none focus:outline-none ">
                  <div className="relative p-0 flex-auto">
                    <img
                      className="w-full h-[200px] rounded-t-lg object-cover"
                      src={banner}
                      width="200"
                      height="267"
                      alt="me"
                    ></img>
                    <div>
                      <h1 className="font-bold text-pike text-center mt-4">
                        ¿Necesitas comprar al por mayor?
                      </h1>
                      <p className="text-center">
                        Tenemos el mejor precio para tu empresa!
                      </p>
                    </div>

                    <form
                      type="POST"
                      className=" rounded px-8 pt-6 pb-8 w-full"
                    >
                      <div>
                        <div className="relative">
                          <label
                            for="name"
                            className="leading-7 text-sm text-gray-600"
                          >
                            Empresa (*)
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <div className="relative">
                          <label
                            for="name"
                            className="leading-7 text-sm text-gray-600"
                          >
                            Email (*)
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <div className="relative">
                          <label
                            for="name"
                            className="leading-7 text-sm text-gray-600"
                          >
                            Teléfono
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            required
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cerrar
                    </button>
                    <button
                      className="text-white bg-pike2 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="submit"
                      /*                       onClick={() => setShowModal(false)}
                       */
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const cambiar = (props) => {
    if (props === "todos") {
      setSelect("todos");
    } else {
      setSelect(props.name);
    }
    fetch(
      `https://api.mercadolibre.com/sites/MLU/search?seller_id=109907868&category=${props.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCategories2(data.results);
        dispatch({
          type: actionType.SET_PRODUCTS,
          products: data.results,
        });
      });
  };

  const Modal = () => {
    return (
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        /* autoPlay={true} */
        interval={5000}
        showArrows={true}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } md:top-0 top-[10vh] bottom-0 left-0 flex justify-center items-center  p-3 hover:opacity-80 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <IoMdArrowDropleft className="w-9 h-9 fill-white shadow-lg rounded-lg bg-inge1" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } md:top-0 top-[10vh] bottom-0 right-0 flex justify-center items-center  md:p-3 hover:opacity-80 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <IoMdArrowDropright className="w-9 h-9 fill-white shadow-lg rounded-lg bg-inge1" />
            </div>
          );
        }}
      >
        {item.img.map((a, index) => (
          <div className="h-full  w-full rounded-t-lg cursor-pointer relative">
            <img
              key={index}
              src={a}
              className="md:h-[800px] rounded-t-lg object-cover p-4 md:p-20 2xl:p-4"
            />
          </div>
        ))}

        {item.video && (
          <>
            <div className="md:flex hidden lg:hidden">
              <ReactPlayer
                url={item.video}
                width="100%"
                height="600px"
                playing={true}
                loop={true}
                controls={true} // Add this line to show controls
              />
            </div>
            <div className="hidden lg:flex">
              <ReactPlayer
                url={item.video}
                width="100%"
                height="800px"
                playing={true}
                loop={true}
                controls={true} // Add this line to show controls
              />
            </div>
            <div className="flex md:hidden">
              <ReactPlayer
                url={item.video}
                width="100%"
                height="400px"
                playing={true}
                loop={true}
                controls={true} // Add this line to show controls
              />
            </div>
          </>
        )}
      </Carousel>
    );
  };

  return (
    <>
      <Header
        handleClick={() => handleClick()}
        handleClickCatalogo={() => handleClickCatalogo()}
        handleClickContacto={() => handleClickContacto()}
      />
      {modal && (
        <div className="fixed h-screen w-screen items-center flex top-0 left-0 bg-black bg-opacity-60 z-[999] md:px-20 py-10">
          <div
            onClick={() => setModal(false)}
            className="bg-white md:top-8 top-20   w-[100px] absolute z-[1000] right-4 md:right-40 2xl:right-24 justify-center text-center font-bold rounded-lg cursor-pointer"
          >
            Cerrar
          </div>
          <Modal />
        </div>
      )}

      <div className="flex flex-col ">
        {/* <Popup /> */}

        {/*   <Slider /> */}
        <h1
          ref={catref}
          className="w-full bg-inge1 mt-[10vh] p-4 text-center font-bold text-white"
        >
          Catálogo
        </h1>
        <h1
       
          className="w-full bg-yellow-300 p-2 text-center text-black mb-4"
        >
          Compra/Venta de grúas y equipos de elevación
        </h1>
        <div className="w-full md:grid-cols-2 lg:grid-cols-4 mx-auto  grid  gap-8 px-4 md:px-20 lg:px-40 md:py-10">
          {ingenal.map((a, index) => (
            <Products
              key={index}
              item={a}
              setModal={setModal}
              setItem={setItem}
            />
          ))}
        </div>
        <h1
          ref={capref}
          className="w-full bg-inge1 p-4 text-center font-bold text-white mb-4 mt-4 "
        >
          Capacitaciones
        </h1>
        <div className="w-full grid lg:grid-cols-3 gap-8 md:px-40 md:py-10">
          {capacitacion.map((a, index) => (
            <Capacitaciones key={index} item={a} />
          ))}
        </div>

        <h1
          ref={ctoref}
          className="w-full bg-inge1 p-4 text-center font-bold text-white mb-4 mt-4 "
        >
          Contacto
        </h1>
        <Contact />
        <h1 className="w-full bg-inge1 p-4 text-center font-bold text-white mb-4 mt-4 ">
          Confían en nosotros
        </h1>
        <div className="flex w-full gap-10 justify-center">
          <img src={imm} alt="imm" className="object-contain w-20 opacity-50" />
          <img
            src={ancap}
            alt="wtc"
            className="object-contain w-20 opacity-50"
          />
          <img
            src={ute}
            alt="ute"
            className="object-contain w-20  opacity-50"
          />
        </div>
        <Pagination
          className="p-2"
          totalPosts={products && products.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Home;
